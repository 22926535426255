<div class="container">
  <div class="details">
    <h1>Contact</h1>
    <p class="about">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio consequuntur necessitatibus quaerat
      doloribus eum sint quisquam sequi. Facilis, perspiciatis. Odio saepe laboriosam ullam placeat? Numquam sit
      perspiciatis dicta architecto asperiores.</p>

    <div class="contacts">
      <h3>Address</h3>
      <div>Lagos, Nigeria</div>
    </div>
    <div class="contacts">
      <h3>Email</h3>
      <div>halexanderfleming@gmail.com</div>
    </div>
  </div>
  <div class="form-box">
    <h1>Contact Form</h1>
    <div class="inputs">
      <div class="input-group">
        <label for="name">Your Name</label>
        <input name="name" type="text" placeholder="Your Name">
      </div>
      <div class="input-group">
        <label for="phone">Your Phone</label>
        <input name="phone" type="text" placeholder="Your Phone">
      </div>
      <div class="input-group">
        <label for="email">Your Email</label>
        <input name="email" type="email" placeholder="Your Email">
      </div>
      <div class="input-group">
        <label for="message">Message</label>
        <input name="message" type="textarea" placeholder="Message">
      </div>
    </div>
    <div class="submit">
      Send Message &#8594;
    </div>
  </div>
</div>
