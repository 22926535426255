<div class="main">
  <div class="tag-1">
    I am
  </div>
  <div class="tag-2">
    Valiant
  </div>
  <div class="tag-3">
    A Software Developer
  </div>
</div>
